import { Defaults } from "../../defaults";
export class Login {
    guardarSesion(user_token, type) {
        // @ts-ignore
        // console.log(Defaults.global);
        $.ajax({
            api: false,
            url: 'modules/login/ajax/guardar-sesion.php',
            method: 'GET',
            data: { user_token }
        }).done(() => {
            if (type === 'user') {
                location.href = 'dashboard';
            }
            else {
                location.href = Defaults.global.panelUrl;
            }
        });
    }
}
