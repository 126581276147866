import { Defaults } from "./defaults";
$(() => {
    Defaults.init();
    setTimeout(() => {
        location.href = 'logout';
    }, ((40 * 60) * 1000));
});
import "expose-loader?exposes[]=$&exposes[]=jQuery!jquery";
import "expose-loader?exposes[]=JSZip!jszip";
import "expose-loader?exposes[]=App!./modules/app";
